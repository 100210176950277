import React, { useState } from "react";

import PaginationSave from "./PaginationSave";
import FoldersMultipleAdd from "../FoldersMultipleAdd";

// Material UI
import { Button, Checkbox, Fade, Tooltip } from "@mui/material";

// Icons
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ModalWindowDownload from "../ModalWindowDownload";
import { useContent } from "../../hooks/ContentContext";
import { useUser } from "../../hooks/UserContext";

const ContentMenu = (props) => {
  const {
    foundResult,
    currentFolder,
    resultIds,
    allChosen,
    setSelectedAll,
    setAllChosen,
    setChosenOnThisPage,
    modalDownload,
    setModalDownload,
    MultipleDownloadServer
  } = props;

  const { 
    chosenItems, 
    fetching, 
    pageNumber, 
    result, 
    clearChosenItems, 
    setSelectedIds,
  } = useContent();

  const {
    userCounter,
    userPlan,
    userDownloadIds,
  } = useUser();

  // Count new download which were added to the download list
  const [newDownloads, setNewDownloads] = useState(0);
  const [oldDownload, setOldDownload] = useState(0);

  // Prevent download hidden from first page
  const [cantDownload, setCantDownload] = useState(false);

  // Selected IDs which were selected
  const [notDownloadedSelected, setNotDownloadedSelected] = useState([]);

  const [downloadedSelected, setDownloadedSelected] = useState([]);

  // First three to download during Trial
  const [allowedItems, setAllowedItems] = useState([]);

  const TextNoCompany = "Please select at least one company";
  const TextAllChosen = "All companies are chosen";
  
  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chosenItems]);

  function setDownloads(oldDownload, newDownloads) {
    setOldDownload(oldDownload);
    setNewDownloads(newDownloads);
  }

  /**
   * Close Modal Window
   */
  function closeModal() {
    setDownloads(0, 0);
    setModalDownload(false);
  }

  // Press Download and open modal window
  function OpenPreDownload() {

    setCantDownload(false);

    var tempChosenItems = Array.isArray(chosenItems) ? chosenItems : [];
    var intersection = tempChosenItems.filter((item) =>
      userDownloadIds.includes(item)
    );
    var notDownloaded = tempChosenItems.filter(
      (item) => !userDownloadIds.includes(item)
    );
    setNotDownloadedSelected(notDownloaded);
    setDownloadedSelected(intersection);
    setDownloads(
      intersection.length,
      Math.abs(intersection.length - chosenItems.length)
    );
    if (!userPlan && currentFolder !== "0" && pageNumber < 2) {
      setAllowedItems(resultIds.slice(0, 3));
      var firstThree = resultIds.slice(0, 3);
      for (let i = 0; i < chosenItems.length; i++) {
        if (!firstThree.includes(chosenItems[i])) {
          setCantDownload(true);
        }
      }
    }

    if (modalDownload === true) {
      setNewDownloads(0);
    }
    setModalDownload((prev) => !prev);
  }

  // Call Modal when Single Download active
  // useEffect(() => {
  //   console.log('test2');
  //   if (chosenItems > 0 && singleDownloadState !== 0) {
  //     OpenPreDownload();
  //     setSingleDownloadState(0);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [singleDownloadState]);

  function UpdateModalDownload(newChosenItems) {
    // setChosenItems(newChosenItems);
    setSelectedIds(newChosenItems);
    setSelectedAll(false);
    setAllChosen(false);
    var intersection = newChosenItems.filter((item) =>
      userDownloadIds.includes(item)
    );
    var notDownloaded = newChosenItems.filter(
      (item) => !userDownloadIds.includes(item)
    );
    setNotDownloadedSelected(notDownloaded);
    setDownloadedSelected(intersection);
    setDownloads(
      intersection.length,
      Math.abs(intersection.length - newChosenItems.length)
    );
  }

  function ReduceChosenItems(ins) {
    switch (ins) {
      case "new":
        // setChosenItems(notDownloadedSelected);
        setSelectedIds(notDownloadedSelected);
        setSelectedAll(false);
        setAllChosen(false);
        setDownloads(0, notDownloadedSelected.length);
        break;
      case "first":
        UpdateModalDownload(chosenItems.slice(0, userCounter));
        break;
      case "old":
        UpdateModalDownload(downloadedSelected);
        break;
      case "visible":
        UpdateModalDownload(allowedItems);
        setCantDownload(false);
        break;
      default:
        break;
    }
  }

  function checkAll(source) {
    var itemsAll = result.distributors;
    var tempArrayAllItems = [];
    for (let i = 0; i < itemsAll.length; i++) {
      if (itemsAll[i]["updated_timestamp"] !== "2024-03-18 15:05:00") {
        tempArrayAllItems.push(itemsAll[i].id);
      }
    }

    // If checkbox
    if (source === "pagination") {
      // All chosen FALSE
      if (!allChosen) {
        setChosenOnThisPage(tempArrayAllItems); // Only this page
        var clearAllFromThisPage = tempArrayAllItems.filter(
          (item) => !chosenItems.includes(item)
        );
        // setChosenItems(clearAllFromThisPage.concat(chosenItems)); // All pages
        setSelectedIds(clearAllFromThisPage.concat(chosenItems));

        // All chosen TRUE on this page
      } else {
        var tempSubstract = [];
        var tempitemsAllIds = itemsAll.map((a) => a.id);
        tempSubstract = chosenItems.filter(
          (item) => !tempitemsAllIds.includes(item)
        );
        // setChosenItems(tempSubstract);
        setSelectedIds(tempSubstract);
        setChosenOnThisPage([]);
      }
      setAllChosen((prevState) => !prevState); // THIS page

      // If check all comes from multibar
    } else if (source === "multibar") {
      if (chosenItems.length > 0) {
        clearChosenItems();
        setAllChosen(false);
        setSelectedAll(false);
      } else {
        // Seems that this is not used
        for (let i = 0; i < itemsAll.length; i++) {
          if (itemsAll[i]["updated_timestamp"] !== "2024-03-18 15:05:00") {
            tempArrayAllItems.push(itemsAll[i].id);
          }
        }
        // setChosenItems(tempArrayAllItems);
        setSelectedIds(tempArrayAllItems);
        setAllChosen(true);
      }
    } else if (source === "select-all") {
      setSelectedAll(true);
      // setChosenItems([]);
      setSelectedIds([]);
      // setChosenItems(result.result_ids);
      setSelectedIds(result.result_ids);
      setAllChosen(true);
      var tempThisPageIds = [];
      for (let i = 0; i < itemsAll.length; i++) {
        if (itemsAll[i]["updated_timestamp"] !== "2024-03-18 15:05:00") {
          tempThisPageIds.push(itemsAll[i].id);
        }
      }
      setChosenOnThisPage(tempThisPageIds);
    } else if (source === "deselect-all") {
      setSelectedAll(false);
      clearChosenItems();
      setAllChosen(false);
      setChosenOnThisPage([]);
    }
  }

  return (
    <div className="content-menu">
      <ModalWindowDownload
        MultipleDownloadServer={MultipleDownloadServer}
        newDownloads={newDownloads}
        setDownloads={setDownloads}
        userCounter={userCounter}
        chosenItems={chosenItems}
        oldDownload={oldDownload}
        ReduceChosenItems={ReduceChosenItems}
        modalDownload={modalDownload}
        setModalDownload={setModalDownload}
        notDownloadedSelected={notDownloadedSelected}
        downloadedSelected={downloadedSelected}
        closeModal={closeModal}
        checkDownload={
          (!userPlan && currentFolder !== "0" && pageNumber > 1) || cantDownload
        }
      />

      <div className="content-menu__checkbox">
        <Checkbox
          disabled={fetching}
          id="multi-select-checkbox"
          checked={allChosen}
          onChange={() => checkAll("pagination")}
          className="checkbox__input"
          color="primary_grey_light"
        />

        <div className="headings__bookmark"></div>
      </div>

      {/* <Tooltip title={chosenItems?.length !== foundResult ? "" : TextAllChosen}> */}
        <Button
          variant="contained"
          color="primary_grey_light"
          onClick={() => checkAll("select-all")}
          startIcon={<DoneAllIcon />}
          className="content-menu__expand-select content-menu__button"
          size="small"
          disabled={fetching || chosenItems?.length === foundResult}
        >
          <span className="expand-select__text">Select All</span>
        </Button>
      {/* </Tooltip> */}

      <Fade
        in={chosenItems.length > 0}
        timeout={{ enter: 400, exit: 300 }}
        easing={{ enter: "ease-in-out", exit: "ease-in-out" }}
      >
        <div>
          <Button
            disabled={fetching}
            variant="contained"
            color="primary_red_light"
            onClick={() => checkAll("deselect-all")}
            endIcon={<CloseIcon />}
            className="content-menu__expand-select content-menu__button"
            size="small"
          >
            {`${chosenItems.length} item${
              chosenItems.length > 1 ? "s" : ""
            } selected`}
          </Button>
        </div>
      </Fade>

      <div className="content-menu__save">
        <PaginationSave />
      </div>

      <Tooltip
        title={
          chosenItems.length > 0 ? "" : "Please select at least one company"
        }
      >
        <div className="content-menu__download">
          <Button
            disabled={fetching || chosenItems.length < 1}
            className="content-menu__button"
            onClick={OpenPreDownload}
            variant="contained"
            color={"primary_grey_light"}
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </div>
      </Tooltip>

      <FoldersMultipleAdd
        chosenItems={chosenItems}
        contentMenuHide={fetching}
        TextNoCompany={TextNoCompany}
      />
    </div>
  );
};

export default ContentMenu;
