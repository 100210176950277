import React, { useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FileFormatsCheckbox from "./FileFormatsCheckbox";
import i18n from "../../utils/i18n";
import { useContent } from "../../hooks/ContentContext";

// Styles of modal window
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

const ModalWindowDownload = (props) => {
  const {
    userCounter,
    newDownloads,
    oldDownload,
    modalDownload,
    notDownloadedSelected,
    downloadedSelected,
    ReduceChosenItems,
    checkDownload,
    MultipleDownloadServer,
    closeModal,
  } = props;

  const { chosenItems } = useContent();

  // File format
  const [fileformats, setFileformats] = useState({
    fullcsv: false,
    emailcsv: false,
    fullxls: true,
    emailxls: false,
  });

  const [fileNameText, setFileNameText] = useState(""); 
  const [textAutoFocus,setTextAutoFocus] = useState(true);

  // List of file formats
  const fileFormatsList = [
    {
      name: "fullxls",
      label: "Company Profile Excel",
    },
    {
      name: "fullcsv",
      label: "Company Profile CSV",
    },
    {
      name: "emailxls",
      label: "Emails Excel",
    },
    {
      name: "emailcsv",
      label: "Emails CSV",
    },
  ];

  const handleFileNameChange = (event) => {
    setFileNameText(event.target.value);
    // Устанавливаем фокус на поле при вводе
    setTextAutoFocus(true);
  };

  // When checkbox changes Type of file formats
  function choseFileDownload(type) {
    setFileformats((prevState) => ({
      ...prevState, // keep all others
      [type]: !prevState[type],
    }));
    setTextAutoFocus(false);
  }

  /**
   * Заголовок модального окна
   * @returns JSX.Element
   */
  const ModalHeader = () => {
    return (
      <div className="modal-summary__heading">
        <div className="modal-summary__text-icon">
          <FileDownloadIcon />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download Summary
          </Typography>
        </div>
        <IconButton
          className="modal-summary__close"
          aria-label="delete"
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  const HiddenContactsInfo = () => {
    return (
      <span className="modal-summary__notify-not-download">
        <span>
          You cannot download hidden contacts during the trial period. Please
          select only those companies that are open for viewing. Or purchase
          full access to download any contacts from the distributor database.
        </span>
        <span className="modal-summary__buttons">
          {userCounter > 0 ? (
            <Button
              onClick={() => ReduceChosenItems("visible")}
              variant="contained"
              color="primary_grey_light"
            >
              Select Visible
            </Button>
          ) : null}
        </span>
      </span>
    );
  };

  /**
   * Возвращает информацию о подтверждении загрузки. Выбор формата файла и кнопки скачать
   * @returns JSX.Element
   */
  const ConfirmDownload = () => {
    return (
      <>
        <span>
          {`You are going to download ${chosenItems.length} distributor${
            chosenItems.length > 1 ? "s" : ""
          }. ${Math.abs(newDownloads)} credit${
            Math.abs(newDownloads) > 1 ? "s" : ""
          } will be deducted from your balance. Previously downloaded distributors (chosen ${oldDownload} ) are not counted, it will be downloaded for free.`}
        </span>
        <span>
          {`Your current balance: ${userCounter} credit${
            userCounter > 1 ? "s" : ""
          }`}
        </span>
        <span>
          {`Balance after download: ${userCounter - newDownloads} credit${
            userCounter > 1 ? "s" : ""
          }`}
        </span>

        <span className="modal-summary__format-h">
        The name that will appear in the downloads list
        </span>

        <TextField 
        label="Set a name for the export file" 
        variant="outlined" 
        fullWidth 
        autoFocus={textAutoFocus}
        value={fileNameText}
        onChange={handleFileNameChange}
        />

        <span className="modal-summary__format-h">
          Choose the file formats to download
        </span>
        <span className="modal-summary__checkboxes">
          {fileFormatsList.map((item) => (
            <FileFormatsCheckbox
              key={item.name}
              label={item.label}
              checked={fileformats[item.name]}
              onChange={() => choseFileDownload(item.name)}
              name={`check_${item.name}`}
            />
          ))}
        </span>

        <span className="modal-summary__buttons">
          <Button
            disabled={
              userCounter < 1 ||
              notDownloadedSelected?.length === 0 ||
              notDownloadedSelected?.length === chosenItems?.length
            }
            onClick={() => ReduceChosenItems("new")}
            variant="outlined"
            color="primary_grey_light"
          >
            Only new
          </Button>
          <Button
            disabled={
              downloadedSelected?.length === chosenItems?.length ||
              downloadedSelected < 1
            }
            onClick={() => ReduceChosenItems("old")}
            variant="outlined"
            color="primary_grey_light"
          >
            Only downloaded
          </Button>

          <Button
            onClick={() => MultipleDownloadServer(fileformats,fileNameText)}
            variant="contained"
            color="primary_grey_light"
          >
            Download
          </Button>
        </span>
      </>
    );
  };

  /**
   * Возвращает информацию о недостаточном количестве кредитов
   * @returns JSX.Element
   */
  const NotEnoughCredits = () => {
    return (
      <>
        <span>{i18n.t("Not enough credits")}</span>
        <span>
          {`Your total balance is ${userCounter} credit${
            userCounter > 1 ? "s" : ""
          } and you have chosen ${chosenItems.length} distributor${
            chosenItems.length > 1 ? "s" : ""
          } of which ${Math.abs(
            newDownloads
          )} are new. This amount need to be deducted from your balance.`}
        </span>
        <span>
          {i18n.t("Please choose fewer distributors or purchase more credits")}
        </span>
        <span className="modal-summary__buttons">
          {userCounter < 1 ? (
            <Button
              href="https://vinaty.com/account/"
              target="_blank"
              variant="outlined"
            >
              Account
            </Button>
          ) : null}

          <Button
            disabled={
              userCounter < 1 ||
              notDownloadedSelected?.length === 0 ||
              notDownloadedSelected?.length === chosenItems?.length
            }
            onClick={() => ReduceChosenItems("new")}
            variant="contained"
            color="primary_grey_light"
          >
            Only new
          </Button>

          <Button
            disabled={downloadedSelected < 1}
            onClick={() => ReduceChosenItems("old")}
            variant="contained"
            color="primary_grey_light"
          >
            Only downloaded
          </Button>

          {userCounter > 0 ? (
            <Button
              onClick={() => ReduceChosenItems("first")}
              variant="contained"
              color="primary_grey_light"
            >
              {`Select first ${userCounter}`}
            </Button>
          ) : null}
        </span>
      </>
    );
  };

  return (
    <Modal
      open={modalDownload}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-summary"
    >
      <Box 
      sx={style} 
      className="modal-summary__modal"
      >
        <ModalHeader />
        
        <div
          className="modal-summary__text"
          id="modal-modal-description"
          // sx={{ mt: 2 }}
        >
          {checkDownload ? (
            <HiddenContactsInfo />
          ) : userCounter >= Math.abs(newDownloads) ? (
            <ConfirmDownload />
          ) : (
            <NotEnoughCredits />
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalWindowDownload;
