import { getToken } from "../utils";
import Api from "../utils/api";

export async function handleFetchUser(props) {
  try {
    const response = await Api.post("auth", {
      data: "token",
      token: getToken(),
      ...props,
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during during user request. Error code u001");
    window.location.replace("https://vinaty.com/login/");
    console.error(error);
  }
}

export async function requestUdSettings() {
  try {
    const response = await Api.post("auth", {
      data: "udsettings",
      token: getToken(),
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during user request. Error code u002");
    // window.location.replace("https://vinaty.com/login/");
    console.error(error);
  }
}



export async function changeUdSettings(column,value) {
  try {
    const response = await Api.post("folders", {
      data: "change_udsettings",
      column: column,
      value: value,
      token: getToken(),
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during user request. Error code u003");
    // window.location.replace("https://vinaty.com/login/");
    console.error(error);
  }
}