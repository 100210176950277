import React from "react";
import { useContent } from "../../hooks/ContentContext";
// import { useUser } from "../../hooks/UserContext";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { GrDocumentCsv } from "react-icons/gr";
// import { Redownload } from "../../utils";

export default function UdDownloadButton(props) {
  const {
    type,downloadArray,addDb,fileName
  } = props;

  const {
    downloadServerFile
  } = useContent();

  // function SingleDownloadAction(fileNameExport,DownloadArray,AddToDb,fileName) {
  //   updateUserDownloads(data["user_downloads"], data["user_counter"]);
  // }

  return (
    <div className="buttons-export">
      <div className="button-export excel-be" onClick={()=>downloadServerFile(true,downloadArray,'excel-'+type,fileName,addDb)}>
      <PiMicrosoftExcelLogoFill /> <span className="button-export__text">Excel</span>
      </div>
      <div className="button-export csv-be" onClick={()=>downloadServerFile(true,downloadArray,'csv-'+type,fileName,addDb)}>
      <GrDocumentCsv /> <span className="button-export__text">Csv</span>
      </div>
    </div>
  );
}
