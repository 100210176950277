import React, { useEffect, useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import { Fade, LinearProgress, Stack } from "@mui/material";
import { hasPersonEmails } from "../../../utils";
import CompanySingleEmail from './CompanySingleEmail';
import EmailIcon from "@mui/icons-material/Email";

const CompanyEmail = ({ fetching, openEmailStatusInfo, companyData }) => {
  const [emails, setEmails] = useState([]);
  const [uniqPersonEmails, setUniqPersonEmails] = useState(0);
  
  useEffect(() => {
    if (companyData) {
      setEmails(companyData["emails"]);
    }
    const countUniqPersonEmails = hasPersonEmails(companyData["emails"]);
    setUniqPersonEmails(countUniqPersonEmails);
  }, [companyData]);
  
  return (
    <>
      {fetching ? (
        <div className={"company__section company__section-emails fade-appear"}>
          <div className="company__emails-h company__heading">
            Emails |
            <div
              className={"company__question-h"}
              onClick={(event) => openEmailStatusInfo(event)}
            >
              <span> Status</span>

              <HelpIcon
                className="question-toggle"
                sx={{ fontSize: 20 }}
                onClick={(event) => openEmailStatusInfo(event)}
              />
            </div>
          </div>

          {emails?.length > 0 ? (
            <div className="emails__list">
              {emails?.filter(el => el.persons === "0").map((el, index) => {
                return (
                  <CompanySingleEmail 
                  el={el} 
                  index={index}
                  key={'email-' + index}
                  openEmailStatusInfo={openEmailStatusInfo}
                  />
                ); 
              })}
            </div>
          ) : fetching && emails?.length === 0 ? (
            <span className="single-bottom-info__no-info no-info--email">
              <EmailIcon />
              No emails found
            </span>
          ) : null}

          {uniqPersonEmails > 0 ?
            <div className="emails__count-persons">
              <div className="emails__count-persons-inner">
                {`${uniqPersonEmails} more email${uniqPersonEmails > 1 ? 's' : ''} in Persons section`}
              </div>
            </div>
          : null}
        </div>
      ) : (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      )}
    </>
  );
};

export default CompanyEmail;
