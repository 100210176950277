import React, { useEffect, useState } from "react";
// import { useContent } from "../../hooks/ContentContext";
// import { useUser } from "../../hooks/UserContext";
import { convertDate } from "../../utils";
import UdDownloadButton from "./UdDownloadButton";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReactCountryFlag from "react-country-flag";
import SignalCellularAlt1BarIcon from "@mui/icons-material/SignalCellularAlt1Bar";
import SignalCellularAlt2BarIcon from "@mui/icons-material/SignalCellularAlt2Bar";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { IoIosWine } from "react-icons/io";
import { LiaGlassWhiskeySolid } from "react-icons/lia";
import { RiBeerLine } from "react-icons/ri";
import { PiBeerBottleFill } from "react-icons/pi";
import { FaBottleDroplet } from "react-icons/fa6";
import SpaIcon from "@mui/icons-material/Spa";
import { GiWineBottle } from "react-icons/gi";
import { BsBoxSeam, BsShopWindow } from "react-icons/bs";
import { PiTruck } from "react-icons/pi";
import LanguageIcon from "@mui/icons-material/Language";
import { IoIosLogIn } from "react-icons/io";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

export default function DownloadItem(props) {
  const {
    item,indexItem
  } = props;

  const [downloadArray, setDownloadArray] = useState([]);

  function ConvertFilers(filters) {
    try {
      const filtersObj = JSON.parse(filters);
      return RenderFilters(filtersObj); 
    } catch (error) {
        return 'No filters';
    }
  }

  useEffect(()=>{
    setDownloadArray(item?.download?.replace(/\s+/g, '').split(","));
  },[item]);

  function RenderFilters(filtersObj) {
    return (
      <div className="download-item__filters">
        
        {/* Location */}

        {filtersObj?.filters?.region?.length > 0 || 
        filtersObj?.filters?.country?.length > 0 || 
        filtersObj?.filters?.province?.length > 0 ? 

          <div className="download-item__sub-h">
            Location:
          </div>

        : null}

        {filtersObj?.filters?.region?.map((regionItem) => (
        <div key={item.id + "-" + regionItem.id} className="filter-label download-item__region">
          <span className="download-item__label">{regionItem.label}</span>
        </div>
        ))}


        {filtersObj?.filters?.country?.map((countryItem) => (
          <div key={item.id + "-" + countryItem.id} className="filter-label download-item__country">
            {item.country_code !== "" ? (
              <ReactCountryFlag
                countryCode={countryItem.code}
                svg
                style={{
                  width: "1.25em",
                  height: "1.25em",
                  marginRight: ".5rem",
                }}
              />
            ) : null}
            <span className="download-item__label">{countryItem.label}</span>
          </div>
        ))}

      {filtersObj?.filters?.province?.map((provinceItem) => (
        <div key={item.id + "-" + provinceItem.province} className="filter-label download-item__province">
          <span className="download-item__label">{provinceItem.province}</span>
        </div>
      ))}

      {/* Company */}

      {filtersObj?.filters?.size?.length > 0 ||  
      filtersObj?.filters?.type?.length > 0  ||
      filtersObj?.filters?.years?.length ||
      filtersObj?.filters?.employees?.length ? 

        <div className="download-item__sub-h">
          Companies:
        </div>

      : null}

      {filtersObj?.filters?.size?.map((sizeItem) => (
        <div key={item.id + "-" + sizeItem.value} className="filter-label download-item__size">
          <span className="download-item__size-icon">
                {sizeItem.value === "small" ? (
                  <SignalCellularAlt1BarIcon />
                ) : sizeItem.value === "medium" ? (
                  <SignalCellularAlt2BarIcon />
                ) : sizeItem.value === "large" ? (
                  <SignalCellularAltIcon />
                ) : null}
              </span>
          
          <span className="download-item__label">{sizeItem.label}</span>
        </div>
      ))}

      {filtersObj?.filters?.type?.map((typeItem) => (
        <div key={item.id + "-" + typeItem.value} className="filter-label download-item__type">
          <span className="item__type-icon">
            {typeItem.value === "retailer" ? (
              <GiWineBottle size={18} />
            ) : typeItem.value === "wholesaler" ? (
              <BsBoxSeam size={14} />
            ) : typeItem.value === "distributor" ? (
              <PiTruck size={18} />
            ) : typeItem.value === "supermarket" ? (
              <BsShopWindow size={14} />
            ) : typeItem.value === "importer" ? (
              <IoIosLogIn size={16} />
            ) : typeItem.value === "E-Commerce" ? (
              <LanguageIcon
                className="e-commerce-force-filters"
                size={12}
              />
            ) : null}
          </span>
          
          <span className="download-item__label">{typeItem.label}</span>
        </div>
      ))}

      {filtersObj?.filters?.years?.map((yearsItem) => (
        <div key={item.id + "-" + yearsItem.value} className="filter-label download-item__years">
          <span className="download-item__label">Years: {yearsItem.label}</span>
        </div>
      ))}

      {filtersObj?.filters?.employees?.map((employItem) => (
        <div key={item.id + "-" + employItem.value} className="filter-label download-item__employees">
          
        <SupervisorAccountIcon />

        <span className="download-item__label">{employItem.label}</span>

        </div>
      ))}

      {filtersObj?.filters?.tier?.map((tierItem) => (
        <div key={item.id + "-" + tierItem.value} className="filter-label download-item__tier">
          
        <span className="download-item__label">{tierItem.label}</span>

        </div>
      ))}

      {/* Products */}

        {filtersObj?.filters?.products?.length > 0 ? 

          <div className="download-item__sub-h">
            Products:
          </div>

        : null}

        {filtersObj?.filters?.products?.map((productItem) => (
          <div key={item.id + "-" + productItem.value} className="filter-label download-item__products">
            
            <span className="download-item__products-icon">
              {productItem.value === "w" ? (
                <IoIosWine size={18} />
              ) : productItem.value === "s" ? (
                <LiaGlassWhiskeySolid size={16} />
              ) : productItem.value === "b" ? (
                <RiBeerLine className="icon-beer" size={18} />
              ) : productItem.value === "c" ? (
                <PiBeerBottleFill size={14} />
              ) : productItem.value === "o" ? (
                <FaBottleDroplet size={14} />
              ) : productItem.value === "wo" ? (
                <SpaIcon size={12} />
              ) : null}
            </span>
            
            <span className="download-item__label">{productItem.label}</span>

          </div>
        ))}

        {filtersObj?.filters?.prod_country?.map((prodCountryItem) => (
          <div key={item.id + "-" + prodCountryItem.value} className="filter-label download-item__products">
            
            {item.country_code !== "" ? (
                <ReactCountryFlag
                  countryCode={prodCountryItem.value}
                  svg
                  style={{
                    width: "1.25em",
                    height: "1.25em",
                    marginRight: ".5rem",
                  }}
                />
              ) : null}
            
            <span className="download-item__label">{prodCountryItem.label}</span>

          </div>
        ))}

      {/* Search */}

      {filtersObj?.text_search?.query.length > 3 ? 
      <>
      <div className="download-item__sub-h">

        Text Search: 

      </div>

      <div className="filter-label download-item__text-search">
      {filtersObj?.text_search?.query}

      </div>
      </>

      : null}

      </div>
    );
  }

  return (
    <div className="download-item">

      <div className="download-item__top">

        <div className="download-item__data download-item__id">{indexItem}</div>
        
        <div className="download-item__data download-item__date">
          {convertDate(item?.date,true)}
        </div>
        
        <div className="download-item__data download-item__name">
          {item?.name?.length > 100 ? item.name.slice(0, 100) + "..." : item?.name}
        </div>

        <div className="download-item__data download-item__qty">
        {item.download 
          ? `${item.download.split(",").filter(el => el.trim() !== "").length} co.`
          : "0 companies"}
        </div>

        <div className="download-item__data download-item__actions">
          <div className="download-item__emails">
            <UdDownloadButton type={'email'} downloadArray={downloadArray} fileName={item?.name?.length > 100 ? item.name.slice(0, 100) + "..." : item?.name}/>
          </div>
          {/* <div className="download-item__phones">
            <UdDownloadButton type={'phone'} downloadArray={downloadArray}/>
          </div> */}
          <div className="download-item__profile">
            <UdDownloadButton type={'profile'} downloadArray={downloadArray} fileName={item?.name?.length > 100 ? item.name.slice(0, 100) + "..." : item?.name}/>
          </div>
        </div>
      </div>

      <div className="download-item__bottom">
        <FilterAltIcon /> 
        <span className="download-item__filters-h">Filters</span> 
        {ConvertFilers(item.filters)}
      </div>
      {/*
      <p> 
      {Array.isArray(item.download) ? item.download.join(", ") : item.download}
      </p> */}
    </div>
  );
}
