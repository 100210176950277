import { getToken } from "../utils";
import Api from "../utils/api";

export async function getUserDownloadsList() {

  try {
    const response = await Api.post("download", {
      data: "download-list",
      token: getToken(),
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    alert("Error code dl001");
    console.error(error);
  }

}