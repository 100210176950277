import React, { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import ContactsSharpIcon from "@mui/icons-material/ContactsSharp";
// import { CopyIcon } from "../../CopyIcon";
import { Button, Collapse, Fade, LinearProgress, Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CompanySingleEmail from './CompanySingleEmail';
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HelpIcon from "@mui/icons-material/Help";

const CompanyStaff = ({ fetching, openEmailStatusInfo, companyData }) => {
  const { id } = companyData;
  const [openedPerson, setOpenedPerson] = useState(0);
  function openPerson(id, index) {
    if (openedPerson !== "c" + id + "p" + index) {
      setOpenedPerson("c" + id + "p" + index);
    } else {
      setOpenedPerson(0);
    }
  }
  
  function findPhoneValidated(phoneString) {
    const cleanedPhoneString = phoneString.replace(/[^\d+]/g, "");
    const foundPhone = companyData?.phones?.find(phone => phone.phone.replace(/[^\d+]/g, "") === cleanedPhoneString);
 
    return(
      <div className={"staff-tel"}>

        {foundPhone?.isvalid === 'Valid' ?
          <span className="staff-tel__status staff-tel__status--verified">
            <CheckBoxIcon />
            Verified
          </span>
        : foundPhone?.isvalid === 'Invalid' ?
          <span className="staff-tel__status staff-tel__status--invalid">
            <CancelIcon />
            Invalid
          </span>
        : 
          <span className="staff-tel__status">
            <HelpIcon />
            Unknown
          </span>
        }

        <span className="staff-tel__value">
            {foundPhone.intern_format !== 'unknown' ? foundPhone?.intern_format : phoneString}
        </span>

      </div>)
    ;
  }

  return (
    <>
      {fetching &&
      companyData?.persons !== "" &&
      companyData?.persons !== 0 &&
      companyData?.persons !== "0" ? (
        <div className="company__section company__section-persons fade-appear">
          <div className="company__persons-h company__heading">Persons</div>

          <div className={"persons"}>
            {companyData?.persons?.split(",")?.map((person, index) => {
              return (
                <div className="persons__row" key={id + "persons" + index}>
                  <div className="persons__container-h">
                    <span className="persons__name">
                      <ContactsSharpIcon className="person-icon"/>
                      {person?.split(":")[0]}
                    </span>

                    {!person?.split(":")[1]?.includes("linkedin") &&
                    !person?.split(":")[1]?.includes("@") &&
                    !person?.split(":")[1]?.includes("No") &&
                    !person?.split(":")[1]?.includes("+") &&
                    person?.split(":")[1] !== undefined &&
                    person?.split(":")[1] !== "undefined" ? (
                      <span className="persons__title">
                        {person?.split(":")[1]}
                      </span>
                    ) : null}

                    {person?.includes("@") ||
                    person?.includes("+") ||
                    person?.includes("linkedin") ? (
                      <Button
                        className="persons__open"
                        color="primary_grey_light"
                        size="small"
                        variant="contained"
                        onClick={() => openPerson(id, index)}
                        endIcon={<ArrowForwardIosIcon className="person-icon"/>}
                        sx={{
                          borderRadius: "2px",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          height: "23px",
                          marginLeft: "20px",
                          textTransform: "none",
                          fontSize: "0.76rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Details
                      </Button>
                    ) : null}
                  </div>

                  <Collapse
                    className="persons__list"
                    in={openedPerson === "c" + id + "p" + index}
                    collapsedSize={0}
                  >
                    <>
                      {person
                        ?.split(":")
                        .filter((field, index) => {
                          return (
                            index > 1 ||
                            (index <= 1 &&
                              (field.includes("linkedin") ||
                                field.includes("@") ||
                                field.includes("+")))
                          );
                        })
                        .map((field, index) => {
                          return (
                            <div key={"person-key-" + index}>
                              {field?.includes("linkedin") ? (
                                <div
                                  key={"linkedin" + id + index}
                                  className="persons__column-linkedin"
                                >
                                  <Button
                                    href={
                                      "https://linkedin" +
                                      field?.split("linkedin")[1]
                                    }
                                    target="_blank"
                                    className="persons__linkedin"
                                    color="blue_button_link"
                                    size="small"
                                    variant="contained"
                                    startIcon={<LinkedInIcon className="person-icon"/>}
                                    sx={{
                                      borderRadius: "2px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Linkedin
                                  </Button>
                                </div>
                              ) : field?.includes("@") &&
                                !field?.includes("linkedin") && companyData.emails.find(item => item.email === field)? (
                                <div
                                  key={"email" + id + index}
                                  className="persons__column-email"
                                >
                                  <EmailIcon className="person-icon"/>

                                  <CompanySingleEmail 
                                    el={Array.isArray(companyData?.emails) ? companyData.emails.find(item => item.email === field) || {} : {}} 
                                    index={index}
                                    key={'pers' + index}
                                    openEmailStatusInfo={openEmailStatusInfo}
                                  />
                                  
                                </div>
                              ) : field?.includes("+") &&
                                !field?.includes("linkedin") ? (
                                <div
                                  key={"phone" + id + index}
                                  className="persons__column-phone"
                                >
                                  <LocalPhoneIcon className="person-icon"/>
                                  {findPhoneValidated(field)}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                    </>
                  </Collapse>
                </div>
              );
            })}
          </div>
        </div>
      ) : !fetching ? (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      ) : null}
    </>
  );
};

export default CompanyStaff;
