import React, { createContext, useState, useContext, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [cheat, setCheat] = useState(0);
  const [userCounter, setUserCounter] = useState("");
  const [userExpired, setUserExpired] = useState(false);
  const [userPlan, setUserPlan] = useState(false);
  const [userViewsIds, setUserViewsIds] = useState([]);
  const [userDownloadIds, setUserDownloadIds] = useState([]);
  const [feedback, setFeedback] = useState(false);
  const [defender, setDefender] = useState(false);
  const [preLoader, setPreloader] = useState(true);
  const [userViewsLeft,setUserViewsLeft] = useState(0);

  useEffect(() => {
    if (userDetails["downloads_ids"] !== undefined) {
      setUserDownloadIds(userDetails["downloads_ids"].split(","));
    }
    if (userDetails["views_ids"] !== undefined) {
      setUserViewsIds(userDetails["views_ids"].split(","));
    }
    // Disable preloader
    if (userDetails.user_id !== undefined) {
      setTimeout(() => {
        setPreloader(false);
      }, 500);
    }
    if (
      userDetails["user_plan"] !== undefined &&
      userDetails["user_plan"] !== 4
    ) {
      setUserPlan(true);
    }

    if (
      userDetails["feedback_status"] === 0 &&
      localStorage.getItem("feedback_status") !== "false"
    ) {
      setFeedback(true);
    }

    setUserCounter(userDetails["user_credits"]);

    // setFeedbackUserStatus(userDetails['feedback_status']);

    if (
      (userDetails.user_id === 1222 || userDetails.user_id === 42) &&
      !defender
    ) {
      setAdmin(true);
    }

    // Check if user expired
    // Если Free trial expired / Pro plan expired
    if (userDetails.user_plan === 1 || userDetails.user_plan === 5) {
      setUserExpired(true);
    } else {
      setUserExpired(false);
    }

    // How many views left
    setUserViewsLeft(userDetails.user_views);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  function updateUserDownloads(dataIds, dataCounter) {
    setUserDownloadIds(dataIds);
    setUserCounter(dataCounter);
  }

  return (
    <UserContext.Provider
      value={{
        userDetails,
        setUserDetails,
        cheat,
        setCheat,
        userExpired,
        setUserExpired,
        admin,
        setAdmin,
        userDownloadIds,
        setUserViewsIds,
        preLoader,
        userCounter,
        userPlan,
        setDefender,
        userViewsIds,
        feedback,
        setUserDownloadIds,
        setUserCounter,
        userViewsLeft,
        updateUserDownloads
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
