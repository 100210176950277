import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
        <div>
        📊 Latest Data Update: Mar 28, 2025
        </div>
        <div>
        🚀 Running Build 1.15.4 (Apr 03, 2025)
        </div>
    </div>
  )
}

export default Footer