import { Button } from "@mui/material";
import React from "react";
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';

const SidebarButton = ({ title, subTitle, onClick, additionalClass, startIconIns,endIconIns = null}) => {
  if (subTitle === 'New') {
    endIconIns = <FiberNewRoundedIcon />;
  }
  
  return (
    <Button
      size={"large"}
      sx={{ fontSize: "18px", fontWeight: "bold", textTransform: "none" }}
      className={`sidebar__button ${additionalClass} ${subTitle === 'New' ? 'sidebar-new' : ''}`}
      color="primary"
      startIcon={startIconIns}
      endIcon={endIconIns}
      onClick={onClick}
    >
      {title}
      {(subTitle.length > 0 && subTitle !== 'New') && (
        <span className="counter-button">{subTitle}</span>
      )}
    </Button>
  );
};

export default SidebarButton;
