import { getToken } from "../utils";
import Api from "../utils/api";

export async function fetchDashboardStats() {
  try {
    const response = await Api.post("dashboard", {
      data: "dashboard_stats",
      token: getToken(),
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchDublicateEmails() {
  try {
    const response = await Api.post("dashboard", {
      data: "dublicate_emails",
      token: getToken(),
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function handleCopyPhonesToValidate() {
  try {
    const response = await Api.post("dashboard", {
      data: "copy_phones",
      token: getToken(),
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function handleUpdateCountryCounter() {
    try {
        const response = await Api.post("dashboard", {
          data: "count_countries",
          token: getToken(),
        });
        return response;
      } catch (error) {
        console.error(error);
      }
}