import React, { useEffect, useState } from "react";
// import { useContent } from "../../hooks/ContentContext";
// import { useUser } from "../../hooks/UserContext";
import DownloadItem from "./DownloadItem";
import UdSettingsDownload from "./UdSettingsDownload";

import { getUserDownloadsList } from "../../actions/downloadlist";
import emptyDownloadsPng from "./../../assets/background_illustration-1048px.png";
import { Box, CircularProgress } from "@mui/material";

export default function UserDashboard() {
  // const {
    // getCookie,
    // SingleDownload,
    // changeBookmarkCounter,
    // setModalDownload,
    // currencies,
    // discountState
  // } = props;

  // const {
  // } = useContent();
  // const { userPlan, userExpired, userViewsIds, setUserViewsIds, userDownloadIds } = useUser();
  
  const [downloadsList, setDownloadsList] = useState([]);
  const [fetchingList, setFetchingList] = useState(true);


  useEffect(() => {
    async function fetchDownloads() {
      const data = await getUserDownloadsList();
      setDownloadsList(data?.downloads);
      
      setTimeout(() => {
        setFetchingList(false);
      }, 1500);

    }
    fetchDownloads();
  }, []);

  return (
    <>

      <div className="ud__container ud__container--2cl">

        <div className="ud__top ud__top-l">

          <div className="ud-heading">
          Dashboard
          </div>

          <div className="ud-subheading">
            Settings and downloaded files
          </div>

        </div>

      </div>

      <div className="ud__container ud__container--2cl">

        <div className="content__block ud__downloads">

          <div className="ud__block-h">
            List of downloaded files
          </div>

          <div className="ud__block-sh">
            Your downloads starting from March 7, 2025
          </div>

          {downloadsList.length > 0 && !fetchingList ?
          <div className="ud__block-content ud__block-downloads">
            <div className="block-downloads__headings">
              <p className="block-downloads__h-uid">
                UID
              </p>
              <p className="block-downloads__h-date">
                Date
              </p>
              <p className="block-downloads__h-name">
                Name
              </p>
              <p className="block-downloads__h-qty">
                Quantity
              </p>
              <p className="block-downloads__h-actions">
                <span>
                  Export Emails
                </span>
                {/* <span>
                  Export Phones
                </span> */}
                <span>
                  Export Profiles
                </span>
              </p>
            </div>
            {downloadsList?.map((item, index) => (
              <DownloadItem key={index} item={item} indexItem={index+1}/>
            ))}
          </div>
          : fetchingList ?
            <div className="ud__block-content ud__block-downloads ud__block-downloads--empty">
              <Box className="ud__loader" sx={{ display: "flex" }}>
                <CircularProgress size="15rem" />
              </Box>
            </div>
          :
          <div className="ud__block-content ud__block-downloads ud__block-downloads--empty">
            <div className="block-downloads__empty-h">
              You don't have any downloaded files yet. All new downloads will be added here.
            </div>
            <img src={emptyDownloadsPng} alt="Vinaty Logo" />
            
          </div>
          }

        </div>

        <div className="content__block ud__download-settings">

          <div className="ud__block-h">
            Download Settings
          </div>

          <div className="ud__block-sh">
            Data export settings. Applied across the application for all exports.
          </div>

          <div className="ud__block-content">
            <UdSettingsDownload />
          </div>

        </div>

    </div> 

    </>
  );
}
