import React from "react";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import UnsubscribeSharpIcon from "@mui/icons-material/UnsubscribeSharp";
import { Tooltip } from "@mui/material";
import { convertDate } from "../../../utils";
import { CopyIcon } from "../../CopyIcon";

const CompanySingleEmail = ({ el, index, openEmailStatusInfo }) => {
  return (
    <>
    <div className="emails__email" key={"em-" + index}>
      <div
        className="emails__status-container"
        onClick={(event) => openEmailStatusInfo(event)}
      >
        {el.email_status === "valid" ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--verified">
              <MarkEmailReadIcon /> Verified
            </span>
          </Tooltip>
        ) : el.email_status === "invalid" ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--inactive">
              <UnsubscribeSharpIcon /> Inactive
            </span>
          </Tooltip>
        ) : el.email_status === "do_not_mail" &&
          (el.status_details === "role_based_catch_all" ||
            el.status_details === "role_based") ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--verified">
              <MarkEmailReadIcon /> Role-based
            </span>
          </Tooltip>
        ) : el.email_status === "do_not_mail" &&
          (el.status_details === "global_suppression" ||
            el.status_details === "toxic" ||
            el.status_details === "possible_trap") ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--inactive">
              <DoNotDisturbIcon /> Do not use
            </span>
          </Tooltip>
        ) : el.email_status === "do_not_mail" &&
          (el.status_details === "mx_forward" ||
            el.status_details === "disposable") ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--verified--yellow">
              <MarkEmailReadIcon /> Redirect?
            </span>
          </Tooltip>
        ) : (el.email_status === "not_checked" || el.email_status === "") ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email is being verified
              </span>
            }
          >
            <span className="emails__status emails__status--notchecked">
              <HourglassTopIcon /> Under review
            </span>
          </Tooltip>
        ) : el.email_status === "catch-all" ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--verified--yellow">
              <MarkEmailReadIcon /> Catch-all
            </span>
          </Tooltip>
        ) : el.email_status === "abuse" ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--verified--yellow">
              <MarkEmailReadIcon /> Abuse
            </span>
          </Tooltip>
        ) : el.email_status === "unknown" &&
          (el.status_details === "antispam_system" ||
            el.status_details === "greylisted") ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--verified--yellow">
              <MarkEmailReadIcon /> Antispam
            </span>
          </Tooltip>
        ) : el.email_status === "unknown" &&
          (el.status_details === "failed_smtp_connection" ||
            el.status_details ===
              "mail_server_temporary_error") ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--notchecked">
              <HourglassTopIcon /> Unknown
            </span>
          </Tooltip>
        ) : el.email_status === "unknown" ? (
          <Tooltip
            placement="left"
            size="small"
            title={
              <span className="info-span">
                Email checked on {convertDate(el.status_date)}
              </span>
            }
          >
            <span className="emails__status emails__status--notchecked">
              <HourglassTopIcon /> Unknown
            </span>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
      <CopyIcon left={false} value={el.email} iconClass={"email-copy"}/>
      <span className={"emails__text-email"}>{el.email}</span>
    </div>
    </>
  );
};

export default CompanySingleEmail;
