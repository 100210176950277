import { getToken } from "../utils";
import Api from "../utils/api";

export async function prepareData(item,requestParams,addToDb,fileName) {
  try {
    const response = await Api.post("download", {
      data: "download",
      token: getToken(),
      item,
      add_to_db: addToDb,
      file_name: fileName,
      filters: JSON.stringify(requestParams),
    });
    if (response.data['app_code'] === 'valid') {
      return response.data;
    } else {
      console.log('REDIRECT to App code error page (download)');
    }
  } catch (error) {
    console.error(error);
  }
}

