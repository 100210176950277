import React, { useEffect, useState } from "react";

import { Checkbox, Collapse } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";

import LockSharpIcon from "@mui/icons-material/LockSharp";
import SignalCellularAlt1BarIcon from "@mui/icons-material/SignalCellularAlt1Bar";
import MarkEmailReadSharpIcon from "@mui/icons-material/MarkEmailReadSharp";
import SignalCellularAlt2BarIcon from "@mui/icons-material/SignalCellularAlt2Bar";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { IoIosLogIn } from "react-icons/io";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import SpaIcon from "@mui/icons-material/Spa";

import Tooltip from "@mui/material/Tooltip";
import BookmarkBorderSharpIcon from "@mui/icons-material/BookmarkBorderSharp";
import BookmarkAddedSharpIcon from "@mui/icons-material/BookmarkAddedSharp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ShareIcon from "@mui/icons-material/Share";

import { GiWineBottle } from "react-icons/gi";
import { BsBoxSeam, BsShopWindow } from "react-icons/bs";
import { PiTruck } from "react-icons/pi";

import { IoIosWine } from "react-icons/io";
import { LiaGlassWhiskeySolid } from "react-icons/lia";
import { RiBeerLine } from "react-icons/ri";
import { PiBeerBottleFill } from "react-icons/pi";
import { FaBottleDroplet } from "react-icons/fa6";

import LanguageIcon from "@mui/icons-material/Language";

import ReactCountryFlag from "react-country-flag";
import { convertDate } from "../../utils";
import { useContent } from "../../hooks/ContentContext";
import { fetchData } from "../../actions/content";
import {
  CompanyAddress,
  CompanyCountries,
  CompanyEmail,
  CompanyFolders,
  CompanyLinkedIn,
  CompanyLinks,
  CompanyName,
  CompanyNote,
  CompanyPhone,
  CompanyProducts,
  CompanySales,
  CompanyStaff,
  CompanyType,
  CompanyVat,
  CompanyYear,
} from "./components";
import { handleAddBookmark } from "../../actions/folders";
import { useUser } from "../../hooks/UserContext";

const label = { inputProps: { "aria-label": "Select distributor" } };

const DistributorItem = (props) => {
  const {
    item,
    id,
    downloaded,
    viewed,
    userPlan,
    openEmailStatusInfo,
    currentFolder,
    pageNumber,
    changeBookmarkCounter,
  } = props;

  const { 
    selectOneItem, 
    chosenItems,
  } = useContent();
  const { setUserViewsIds, userViewsLeft } = useUser();

  // Mark this company as viewed
  const [companyViewed, setCompanyViewed] = useState(false);

  // Bookmark value
  const [inBookmark, setInBookmark] = useState(false);

  // If User reporter innacurany

  //State to show Importer on the first place
  const [companyTypesArr, setCompanyTypesArr] = useState([]);

  //State to show Wine on the first place of products
  const [companyProductsArr, setCompanyProductsArr] = useState([]);

  // Open List of Types in Top Part of Company
  const [openTypes, setOpenTypes] = useState(false);

  // Open List of Products in Top Part of Company
  const [openProducts, setOpenProducts] = useState(false);

  // Wait for Types
  const [typesLoaded, setTypesLoaded] = useState(false);

  // Wait for Types
  const [productsLoaded, setProductsLoaded] = useState(false);

  // New Type of Company Get Data
  const [companyData, setCompanyData] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setCompanyViewed(viewed);
  }, [viewed]);

  // Check if item is in bookmarks
  useEffect(() => {
    const checkBookmark = () => {
      if (parseInt(item.bookmark) === 1) {
        setInBookmark(true);
      }
    };

    const checkLastEmailUpdate = () => {
      let lastEmailUpdate = "---";
      if (item?.status_date?.length > 0) {
        const sortedDates = item.status_date.sort();
        lastEmailUpdate = sortedDates[sortedDates.length - 1];
        if (lastEmailUpdate !== "0") {
          lastEmailUpdate = convertDate(lastEmailUpdate);
        }
      }
    };

    const sortTypes = () => {
      const tempTypesArr = item?.type?.split(",");
      const tempTypeArrOrder = tempTypesArr?.reduce((acc, type) => {
        switch (type.toLowerCase()) {
          case "i":
          case "importer":
            acc[0] = "Importer";
            break;
          case "d":
          case "distributor":
            acc[1] = "Distributor";
            break;
          case "w":
          case "wholesaler":
            acc[2] = "Wholesaler";
            break;
          case "s":
          case "supermarket":
            acc[3] = "Supermarket";
            break;
          case "r":
          case "retailer":
            acc[4] = "Retailer";
            break;
          case "e":
          case "e-commerce":
            acc[5] = "E-Commerce";
            break;
          default:
            break;
        }
        return acc;
      }, []);
      setCompanyTypesArr(tempTypeArrOrder.filter(Boolean));
      setTimeout(() => setTypesLoaded(true), 600);
    };

    const sortProducts = () => {
      const tempProductsArr = item.spec.split(",");
      const tempProdArrOrder = tempProductsArr.reduce((acc, product) => {
        switch (product) {
          case "w":
            acc[0] = "Wine";
            break;
          case "wo":
            acc[1] = "Organic";
            break;
          case "s":
            acc[2] = "Spirits";
            break;
          case "b":
            acc[3] = "Beer";
            break;
          case "c":
            acc[4] = "Cider";
            break;
          case "o":
            acc[5] = "Olive";
            break;
          default:
            break;
        }
        return acc;
      }, []);
      setCompanyProductsArr(tempProdArrOrder.filter(Boolean));
      setTimeout(() => setProductsLoaded(true), 600);
    };

    checkBookmark();
    checkLastEmailUpdate();
    sortTypes();
    sortProducts();
    // checkEmailStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  // State for status of open/closed buttom of the Distributor
  const [openButtomDistributor, setOpenButtomDistributor] = useState(false);

  // Handler for status of open/closed buttom of the Distributor
  function openButtomItem() {
    if (item?.updated_timestamp === "2024-03-18 15:05:00") {
      addUserHnPt();
    }
    if (
      userPlan ||
      (!userPlan && id < 3 && pageNumber < 2) ||
      currentFolder === "0"
    ) {
      setOpenButtomDistributor(!openButtomDistributor);
    }
  }

  // Check if this item added to the folder

  // Add item to Bookmark
  function addToBookmark() {
    var action = "add";
    changeBookmarkCounter(1);
    if (inBookmark) {
      action = "remove";
      changeBookmarkCounter(-1);
    }
    setInBookmark((current) => !current);
    const params = {
      item_id: item.id,
      action: action,
    };
    handleAddBookmark(params);
  }

  function addUserHnPt() {
    alert("block");
  }

  function getDataCompany() {
    const params = {
      item_id: item.id,
    };
    fetchData(params).then((data) => {
      if (data["status"] === "1") {
        setCompanyData({
          ...data["company"],
          emails: data["emails"],
          phones: data["phones"],
        });
        openButtomItem();
        domChangeViews();
        setCompanyViewed(true);
        setUserViewsIds((oldArray) => [...oldArray, item.id]);
        setTimeout(() => {
          setFetching(true);
        }, 600);
      } else if (data["status"] === "0") {
        noViews();
        setFetching(false);
      }
    });
  }

  // function dom change views counter
  function domChangeViews() {
    if (!companyViewed) {
      var block = document.getElementById("views");
      var oldText = block.textContent;
      var newText = 0;
      if (parseInt(oldText) <= 0) {
        newText = 0;
      } else {
        newText = parseInt(oldText) - 1;
      }
      block.textContent = newText.toString();
    }
    setCompanyViewed(true);
  }

  function noViews() {
    // console.log("No Views");
  }

  // function exportThisCompany() {
  //   selectOneItem('single_download',item.id);
  //   // setModalDownload(true);
  //   setSingleDownloadState(item.id);
  // }

  return (
    <div
      key={id}
      className={
        "content__block content__block--item" +
        (item.updated_timestamp === "2024-03-18 15:05:00"
          ? " content__block--item--hidden"
          : "") +
        (downloaded ? " item--downloaded" : "") +
        (companyViewed ? " item--viewed" : "") +
        (!userPlan && (id > 2 || pageNumber > 1) && currentFolder !== "0"
          ? " content__block--item--preview"
          : "")
      }
    >
      <div className="item__container-top">
        <div className="item__checkbox">
          <Checkbox
            id={"single-select-" + item.id}
            color={"primary_grey_light"}
            value={item.id}
            {...label}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
            onChange={() => selectOneItem('checkbox',item.id)}
            checked={chosenItems?.includes(item.id)}
          />
        </div>

        <div
          className={
            "item__name" +
            (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)
              ? " textshadow"
              : "")
          }
        >
          <IconButton
            onClick={addToBookmark}
            size={"small"}
            className={
              "item__button-bookmark" +
              (inBookmark ? " item__button-bookmark--active" : "")
            }
            color="primary"
            aria-label="add an alarm"
          >
            {inBookmark ? (
              <BookmarkAddedSharpIcon style={{ fontSize: 26 }} />
            ) : (
              <BookmarkBorderSharpIcon style={{ fontSize: 26 }} />
            )}
          </IconButton>

          {item.year === "2024" ||
          item.year === "2023" ||
          item.year === "2025" ? (
            <span className="item__new-market">New on the Market</span>
          ) : null}

          {item.name !== "" ? (
            <div
              className={
                "item__company-trade-name" +
                (!userPlan &&
                currentFolder !== "0" &&
                (id > 2 || pageNumber > 1)
                  ? " textshadow"
                  : "")
              }
              onClick={
                !userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)
                  ? null
                  : getDataCompany
              }
            >
              {item.name}
            </div>
          ) : (
            <span className="no-info">No info</span>
          )}
        </div>

        <div className="item__action-signs">
          <span className="action-signs__icons">
            {downloaded ? (
              <Tooltip
                title={
                  <span className="info-span">
                    You have previously downloaded this company's contact
                    information. You can access it in your Dashboard. Re-downloading is free.
                  </span>
                }
                size="small"
              >
                <div
                  className="item__downloaded-sign"
                  // onClick={() => SingleDownload(item.id)}
                >
                  <DownloadIcon />
                </div>
              </Tooltip>
            ) : null}

            {companyViewed ? (
              <Tooltip
                title={
                  <span className="info-span">
                    You have previously viewed this company's contact
                    information.
                  </span>
                }
                size="small"
              >
                <div className="item__viewed-sign">
                  <VisibilityIcon />
                </div>
              </Tooltip>
            ) : null}

            {item?.class === 0 || item?.class === "0" || item?.class === "C" ? (
              <span className="class class-c">
                <span className="class__letter">C</span>
                Tier
              </span>
            ) : item?.class === "B" || item?.class === "b" ? (
              <span className="class class-b">
                <span className="class__letter">B</span>
                Tier
              </span>
            ) : item?.class === "A" || item?.class === "a" ? (
              <span className="class class-a">
                <span className="class__letter">A</span>
                Tier
              </span>
            ) : null}
          </span>
        </div>

        <div className="item__region">
          {item.region === "north-america" ? (
            "North America"
          ) : item.region === "south-america" ? (
            "South America"
          ) : item.region === "" ? (
            <span className="no-info">No info</span>
          ) : (
            item.region
          )}
        </div>

        <div className="item__country">
          {item.country_code !== "" ? (
            <ReactCountryFlag
              countryCode={item.country_code}
              svg
              style={{
                width: "1.65em",
                height: "1.65em",
              }}
            />
          ) : null}

          {item.country !== "" ? (
            <span className="country__text">{item.country}</span>
          ) : (
            <span className="no-info">No info</span>
          )}
        </div>

        <div className="item__type">
          {Array.isArray(companyTypesArr) &&
          companyTypesArr.length > 0 &&
          typesLoaded ? (
            <div className="type__container">
              <div className="type__first">
                <span className="item__type-icon">
                  {companyTypesArr[0] === "Retailer" ? (
                    <GiWineBottle size={20} />
                  ) : companyTypesArr[0] === "Wholesaler" ? (
                    <BsBoxSeam size={16} />
                  ) : companyTypesArr[0] === "Distributor" ? (
                    <PiTruck size={20} />
                  ) : companyTypesArr[0] === "Supermarket" ? (
                    <BsShopWindow size={20} />
                  ) : companyTypesArr[0] === "Importer" ? (
                    <IoIosLogIn size={20} />
                  ) : companyTypesArr[0] === "E-Commerce" ? (
                    <LanguageIcon className={"e-commerce-force"} size={16} />
                  ) : null}
                </span>
                <span className="item__type-text">{companyTypesArr[0]}</span>
                {companyTypesArr?.length > 1 ? (
                  <IconButton
                    onClick={() => setOpenTypes((prev) => !prev)}
                    size={"small"}
                    className={
                      "type__opener" +
                      (openTypes === true ? " type__opener--open" : "")
                    }
                    color="primary"
                  >
                    {openTypes ? (
                      <ArrowForwardIosIcon style={{ fontSize: 16 }} />
                    ) : (
                      <span className="type__counter">
                        {"+" + (companyTypesArr?.length - 1)}
                      </span>
                    )}
                  </IconButton>
                ) : null}
              </div>
              <Collapse className="type__list" in={openTypes} collapsedSize={0}>
                <>
                  {companyTypesArr?.slice(1)?.map((typeDistr, index) => {
                    return (
                      <span key={"comp" + index + id} className="type__other">
                        <span className="item__type-icon">
                          {typeDistr === "Retailer" ? (
                            <GiWineBottle size={20} />
                          ) : typeDistr === "Wholesaler" ? (
                            <BsBoxSeam size={16} />
                          ) : typeDistr === "Distributor" ? (
                            <PiTruck size={20} />
                          ) : typeDistr === "Supermarket" ? (
                            <BsShopWindow size={20} />
                          ) : typeDistr === "Importer" ? (
                            <IoIosLogIn size={20} />
                          ) : typeDistr === "E-Commerce" ? (
                            <LanguageIcon
                              className="e-commerce-force"
                              size={16}
                            />
                          ) : null}
                        </span>

                        <span className="item__type-text">{typeDistr}</span>
                      </span>
                    );
                  })}
                </>
              </Collapse>
            </div>
          ) : !typesLoaded ? (
            <Fade
              unmountOnExit={true}
              in={!typesLoaded}
              timeout={{ enter: 400, exit: 200 }}
            >
              <span className="wide-loader-item">
                <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
                  <LinearProgress
                    color="primary_grey_light_light"
                    sx={{
                      height: "18px",
                      borderRadius: "2px",
                    }}
                  />
                </Stack>
              </span>
            </Fade>
          ) : (
            <span className="no-info">No info</span>
          )}
        </div>

        <div className="item__products">
          {Array.isArray(companyProductsArr) &&
          companyProductsArr.length > 0 &&
          productsLoaded ? (
            <div className="products__container">
              <div className="products__first">
                <span className="item__products-icon">
                  {companyProductsArr[0] === "Wine" ? (
                    <IoIosWine size={20} />
                  ) : companyProductsArr[0] === "Spirits" ? (
                    <LiaGlassWhiskeySolid size={16} />
                  ) : companyProductsArr[0] === "Beer" ? (
                    <RiBeerLine className="icon-beer" size={18} />
                  ) : companyProductsArr[0] === "Cider" ? (
                    <PiBeerBottleFill size={20} />
                  ) : companyProductsArr[0] === "Olive" ? (
                    <FaBottleDroplet size={20} />
                  ) : companyProductsArr[0] === "Organic" ? (
                    <SpaIcon size={16} />
                  ) : null}
                </span>
                <span className="item__products-text">
                  {companyProductsArr[0]}
                </span>
                {companyProductsArr.length > 1 ? (
                  <IconButton
                    onClick={() => setOpenProducts((prev) => !prev)}
                    size={"small"}
                    className={
                      "products__opener" +
                      (openProducts === true ? " products__opener--open" : "")
                    }
                    color="primary"
                  >
                    {openProducts ? (
                      <ArrowForwardIosIcon style={{ fontSize: 16 }} />
                    ) : (
                      <span className="products__counter">
                        {"+" + (companyProductsArr.length - 1)}
                      </span>
                    )}
                  </IconButton>
                ) : null}
              </div>
              <Collapse
                className="products__list"
                in={openProducts}
                collapsedSize={0}
              >
                <>
                  {Array.isArray(companyProductsArr) &&
                    companyProductsArr.slice(1).map((productDistr, index) => {
                      // if (index > 0) {
                      return (
                        <span
                          key={"products-" + index + id}
                          className="products__other"
                        >
                          <span className="item__products-icon">
                            {productDistr === "Wine" ? (
                              <IoIosWine size={20} />
                            ) : productDistr === "Spirits" ? (
                              <LiaGlassWhiskeySolid size={16} />
                            ) : productDistr === "Beer" ? (
                              <RiBeerLine className="icon-beer" size={18} />
                            ) : productDistr === "Cider" ? (
                              <PiBeerBottleFill size={20} />
                            ) : productDistr === "Olive" ? (
                              <FaBottleDroplet size={20} />
                            ) : productDistr === "Organic" ? (
                              <SpaIcon className="icon-organic" size={14} />
                            ) : null}
                          </span>

                          <span className="item__products-text">
                            {productDistr}
                          </span>
                        </span>
                      );
                      // }
                    })}
                </>
              </Collapse>
            </div>
          ) : !typesLoaded ? (
            <Fade
              unmountOnExit={true}
              in={!typesLoaded}
              timeout={{ enter: 400, exit: 200 }}
            >
              <span className="wide-loader-item">
                <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
                  <LinearProgress
                    color="primary_grey_light_light"
                    sx={{
                      height: "18px",
                      borderRadius: "2px",
                    }}
                  />
                </Stack>
              </span>
            </Fade>
          ) : (
            <span className="no-info">No info</span>
          )}
        </div>

        <div className="item__size">
          {item.size !== "" ? (
            <div className="item__company-size">
              <span className="item__size-icon">
                {item.size === "Small" ? (
                  <SignalCellularAlt1BarIcon />
                ) : item.size === "Medium" ? (
                  <SignalCellularAlt2BarIcon />
                ) : item.size === "Large" ? (
                  <SignalCellularAltIcon />
                ) : null}
              </span>

              <span className="item__size-label">{item.size}</span>
            </div>
          ) : (
            <span className="no-info">No info</span>
          )}
        </div>

        <div className="item__emails-short">
          <div
            className={
              "contacts-counter__container" +
              (item.email_counter === 0 ? " contacts-counter-zero" : "")
            }
          >
            <span className="contacts-counter__icon">
              <MarkEmailReadSharpIcon />
            </span>
            <span className="contacts-counter__counter">
              {item.email_counter}
            </span>
          </div>
        </div>

        <div className="item__phones">
          <div
            className={
              "contacts-counter__container" +
              (item?.tel_counter === 0 ? " contacts-counter-zero" : "")
            }
          >
            <span className="contacts-counter__icon">
              <LocalPhoneIcon />
            </span>
            <span className="contacts-counter__counter">
              {item?.tel_counter}
            </span>
          </div>
        </div>

        <div className="item__linkedin">
          <div
            className={
              "contacts-counter__container" +
              (item?.linkedin_counter === 0 ? " contacts-counter-zero" : "")
            }
          >
            <span className="contacts-counter__icon">
              <LinkedInIcon />
            </span>
            <span className="contacts-counter__counter">
              {item?.linkedin_counter}
            </span>
          </div>
        </div>

        <div className="item__social">
          <div
            className={
              "contacts-counter__container" +
              (parseInt(item?.x_s) +
                parseInt(item?.linkedin_s?.length) +
                parseInt(item?.facebook_s) +
                parseInt(item?.instagram_s) +
                parseInt(item?.website_s) ===
              0
                ? " contacts-counter-zero"
                : "")
            }
          >
            <span className="contacts-counter__icon">
              <ShareIcon />
            </span>
            <span className="contacts-counter__counter">
              {parseInt(item?.x_s) +
                parseInt(item?.linkedin_s?.length) +
                parseInt(item?.facebook_s) +
                parseInt(item?.instagram_s) +
                parseInt(item?.website_s)}
            </span>
          </div>
        </div>

        <div
          className={
            "item__opener" +
            (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)
              ? " item__opener--closed"
              : "")
          }
        >
          {!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1) ? (
            <Tooltip
              title={
                <span className="info-span">
                  This company is not available for viewing. During the trial
                  period, you can view the first three companies in the search
                  results.
                </span>
              }
              size="medium"
            >
              <IconButton
                size={"large"}
                className={
                  "item__button-icon-open" +
                  (openButtomDistributor === true
                    ? " item__button-icon-open--open"
                    : "")
                }
                color="opener_locked"
              >
                <LockSharpIcon style={{ fontSize: 26 }} />
              </IconButton>
            </Tooltip>
          ) : (userViewsLeft === '0' && !viewed) ?
            <Tooltip
            title={
              <span className="info-span">
                Unfortunately, you've used up all your Views. You can still access companies you've opened before, but to view new ones, please purchase an Access Plan.
              </span>
            }
            size="medium"
            > 
              <IconButton
                size={"large"}
                className={"item__button-icon-open"}
                color="primary"
              >
                <ArrowForwardIosIcon style={{ fontSize: 26 }} />
              </IconButton>
            </Tooltip>
            : (
            <IconButton
              onClick={getDataCompany}
              size={"large"}
              className={
                "item__button-icon-open" +
                (openButtomDistributor === true
                  ? " item__button-icon-open--open"
                  : "")
              }
              color="primary"
            >
              <ArrowForwardIosIcon style={{ fontSize: 26 }} />
            </IconButton>
          )}
        </div>
      </div>

      <Collapse
        className="item__container-bottom"
        in={openButtomDistributor}
        timeout="auto"
        unmountOnExit
      >
        <>
          <div className="container-bottom__left">
            <CompanyName companyData={companyData} fetching={fetching} />
            <CompanyAddress
              companyData={companyData}
              fetching={fetching}
              id={id}
            />
            <CompanyVat companyData={companyData} fetching={fetching} />
            <CompanyYear companyData={companyData} fetching={fetching} />
            <CompanyType
              companyData={companyData}
              fetching={fetching}
              id={id}
            />
            <CompanyCountries companyData={companyData} fetching={fetching} />
            <CompanyProducts companyData={companyData} fetching={fetching} />
            <CompanySales companyData={companyData} fetching={fetching} />
          </div>

          <div className="container-bottom__center">
            <CompanyLinks companyData={companyData} fetching={fetching} />
            <CompanyLinkedIn companyData={companyData} fetching={fetching} />
            <CompanyEmail
              fetching={fetching}
              openEmailStatusInfo={openEmailStatusInfo}
              companyData={companyData}
            />
            <CompanyPhone companyData={companyData} fetching={fetching} />

            <CompanyStaff companyData={companyData} fetching={fetching} openEmailStatusInfo={openEmailStatusInfo}/>
          </div>

          <div className="container-bottom__right">
            <CompanyNote companyData={companyData} fetching={fetching} />
            <CompanyFolders
              companyData={companyData}
              fetching={fetching}
              downloaded={downloaded}
            />
          </div>
        </>
      </Collapse>
    </div>
  );
};

export default DistributorItem;
