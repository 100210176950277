import FolderIcon from "@mui/icons-material/Folder";
import EmailIcon from "@mui/icons-material/Email";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import TableRowsIcon from "@mui/icons-material/TableRows";
import StarIcon from "@mui/icons-material/Star";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BookmarkIcon from "@mui/icons-material/Bookmark";

export const foldersIconsArray = [
    { icon: FolderIcon, name: "FolderIcon" },
    { icon: EmailIcon, name: "EmailIcon" },
    { icon: DriveFolderUploadIcon, name: "DriveFolderUploadIcon" },
    { icon: FolderSpecialIcon, name: "FolderSpecialIcon" },
    { icon: FindInPageIcon, name: "FindInPageIcon" },
    { icon: FolderOffIcon, name: "FolderOffIcon" },
    { icon: TableRowsIcon, name: "TableRowsIcon" },
    { icon: StarIcon, name: "StarIcon" },
    { icon: HourglassEmptyIcon, name: "HourglassEmptyIcon" },
    { icon: DoneIcon, name: "DoneIcon" },
    { icon: CancelIcon, name: "CancelIcon" },
    { icon: MonetizationOnIcon, name: "MonetizationOnIcon" },
    { icon: NotificationImportantIcon, name: "NotificationImportantIcon" },
    { icon: FeedbackIcon, name: "FeedbackIcon" },
    { icon: LocalPhoneIcon, name: "LocalPhoneIcon" },
    { icon: BookmarkIcon, name: "BookmarkIcon" },
  ];

export const foldersColorsArray = [
    "#df6b69",
    "#ea7fa2",
    "#c683d2",
    "#a389d2",
    "#8390de",
    "#68b6f5",
    "#5ed1df",
    "#66cfc5",
    "#93d296",
    "#e3ec8a",
    "#f9bb61",
    "#ff9d7e",
    "#a98e84",
    "#b4bfc4",
    "#6a6a6a",
    "#c3dcf2",
  ];

  export const currenciesArray = [
    { icon: FolderIcon, name: "DKK", code: "DK", full_name: "Danish krone" },
    { icon: FolderIcon, name: "EUR", code: "EU", full_name: "Euro" },
    { icon: FolderIcon, name: "GBP", code: "GB", full_name: "British Pound" },
    { icon: FolderIcon, name: "PLN", code: "PL", full_name: "Polish Zloty" },
    { icon: FolderIcon, name: "USD", code: "US", full_name: "US Dollar" },
    { icon: FolderIcon, name: "EUR", code: "AT", full_name: "Euro" },
    { icon: FolderIcon, name: "EUR", code: "FI", full_name: "Euro" },
    { icon: FolderIcon, name: "EUR", code: "CY", full_name: "Euro" },
    { icon: FolderIcon, name: "EUR", code: "ES", full_name: "Euro" },
    { icon: FolderIcon, name: "EUR", code: "EE", full_name: "Euro" },
    { icon: FolderIcon, name: "EUR", code: "DE", full_name: "Euro" },
    { icon: FolderIcon, name: "EUR", code: "BE", full_name: "Euro" },
    { icon: FolderIcon, name: "BGN", code: "BG", full_name: "Bulgarian lev" },
    { icon: FolderIcon, name: "USD", code: "US", full_name: "US Dollar" },
    { icon: FolderIcon, name: "USD", code: "NZ", full_name: "US Dollar" }, // Data in USD
    { icon: FolderIcon, name: "CZK", code: "CZ", full_name: "Czech koruna" },
    { icon: FolderIcon, name: "NOK", code: "NO", full_name: "Norwegian Krone" }, // NO data in NOK thousand
    { icon: FolderIcon, name: "EUR", code: "LT", full_name: "Euro" },
    { icon: FolderIcon, name: "HUF", code: "HU", full_name: "Hungarian Forint" },
    { icon: FolderIcon, name: "HRK", code: "HR", full_name: "Croatian Kuna" }, // До 2023, теперь EUR
    { icon: FolderIcon, name: "CHF", code: "CH", full_name: "Swiss Franc" },
    { icon: FolderIcon, name: "SEK", code: "SE", full_name: "Swedish Krona" },
    { icon: FolderIcon, name: "RON", code: "RO", full_name: "Romanian Leu" },
    { icon: FolderIcon, name: "ISK", code: "IS", full_name: "Icelandic Króna" },
    { icon: FolderIcon, name: "RSD", code: "RS", full_name: "Serbian Dinar" },
    { icon: FolderIcon, name: "MKD", code: "MK", full_name: "Macedonian Denar" },
    { icon: FolderIcon, name: "ALL", code: "AL", full_name: "Albanian Lek" },
    { icon: FolderIcon, name: "MDL", code: "MD", full_name: "Moldovan Leu" },
    { icon: FolderIcon, name: "GEL", code: "GE", full_name: "Georgian Lari" },
    { icon: FolderIcon, name: "UAH", code: "UA", full_name: "Ukrainian Hryvnia" },
    { icon: FolderIcon, name: "BYN", code: "BY", full_name: "Belarusian Ruble" },
    { icon: FolderIcon, name: "RUB", code: "RU", full_name: "Russian Ruble" },
    { icon: FolderIcon, name: "TRY", code: "TR", full_name: "Turkish Lira" },
    { icon: FolderIcon, name: "EUR", code: "LV", full_name: "Euro" },
    { icon: FolderIcon, name: "EUR", code: "XK", full_name: "Euro" },
  ];