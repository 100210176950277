import React, {useState,useEffect} from "react";
// import { useContent } from "../../hooks/ContentContext";
// import { useUser } from "../../hooks/UserContext";
import { requestUdSettings } from "../../actions/user";
import { changeUdSettings } from "../../actions/user";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Box, CircularProgress,Button } from "@mui/material";
import TextField from '@mui/material/TextField';

export default function UdSettingsDownload() {

  // const { } = props;

  // const { } = useContent();

  // const {  } = useUser();

  const [filterInvalid,setFilterInvalid] = useState(true);
  const [filterDuplicates,setFilterDuplicates] = useState(false);
  const [filterWords, setFilterWords] = useState("");

  const [fetchingSettings,setFetchingSettings] = useState(true);

  const [saved, setSaved] = useState(false);

  const handleChange = (event) => {
    setFilterWords(event.target.value);
  };

  useEffect(()=>{
    requestUdSettings().then((data) => {
      setFilterInvalid(data?.email_filter_invalid === false ? false : true);
      setFilterDuplicates(data?.email_remove_duplicates === false ? false : true);
      setFilterWords(data?.email_filter_words);
      setTimeout(()=>{
        setFetchingSettings(false);
      },1000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function innerChangeUdSettings(column,value){
    changeUdSettings(column,value).then((data) => {
      if (column === 'email_filter_invalid') {
        setFilterInvalid(!filterInvalid);
      } else if (column === 'email_remove_duplicates') {
        setFilterDuplicates(!filterDuplicates);
      } else if (column === 'email_filter_words') {
        setFilterWords(data.email_filter_words);
        setSaved(true);
        setTimeout(() => setSaved(false), 2000); 
      }
    });
  }

  return (
    <>
    {fetchingSettings ?
    <div className="ud__block-content ud__block-downloads ud__block-downloads--empty">
      <Box className="ud__loader" sx={{ display: "flex" }}>
        <CircularProgress size="10rem" />
      </Box>
    </div>
    :
    <div className="udsettings">

      <div className="udsettings__row">
        <FormControlLabel
          label={"Remove Invalid Emails"}
          labelPlacement="start"
          control={
            <Switch
              checked={filterInvalid}
              onChange={()=>innerChangeUdSettings('email_filter_invalid',!filterInvalid)}
              size="large"
            />
          }
        />
        <div className="udsettings__add-info">
          Removes <b>All</b> emails with <b>Invalid</b> status.
        </div>
      </div>

      <div className="udsettings__row">
        <FormControlLabel
          label={"Remove Duplicate Emails"}
          labelPlacement="start"
          control={
            <Switch
              checked={filterDuplicates}
              onChange={()=>innerChangeUdSettings('email_remove_duplicates',!filterDuplicates)}
              size="large"
            />
          }
        />
        <div className="udsettings__add-info">
          Removes duplicates within a <b>single</b> company in <b>Emails export</b>.
        </div>
      </div>

      <div className="udsettings__row udsettings__row--text">

        <div className="udsettings__text-header">
          Filter Emails Domains
        </div>

        <div className="udsettings__add-info">
          Enter words for filtering, separated by <b>commas</b>. For example, "gmail" will remove emails containing "@gmail.com". <b>Only the part after "@" is checked for filtering.</b> <b>Applied for Email Export.</b>
        </div>

        <TextField
          id="outlined-multiline-flexible"
          label="Filter words"
          multiline
          minRows={2}
          maxRows={4}
          fullWidth
          onChange={handleChange}
          value={filterWords}
        />

        <Button 
        variant="contained" 
        color="primary" 
        onClick={()=>innerChangeUdSettings('email_filter_words',filterWords)}
        style={{ marginTop: "10px" }}
        >
        Save
        </Button>
        {saved && <span className="ud__saved">Saved</span>}
      </div>

    </div>
    }
    </>
  );
}
