import React from "react";
import { Divider, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CopyIcon = ({ value, left, iconClass = null, addClass = null }) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopied(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <>
      {left ? (
        <Divider sx={{ height: 28, m: 0.5, paddingLeft: 1 }} orientation="vertical" />
      ) : null}
      <IconButton onClick={copyToClipboard} color="primary" className={iconClass}>
        <ContentCopyIcon />
      </IconButton>

      {!left ? (
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      ) : null}
      {copied ? (
        <span className={"email-save-span " + addClass}>Copied to Clipboard</span>
      ) : null}
    </>
  );
};

export default CopyIcon;
