import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HelpIcon from "@mui/icons-material/Help";
import { convertDate } from "../utils";

// Refactored

export default function PhoneData(props) {
  const { itemid, phones } = props;

  const [openedPhoneDetails, setOpenedPhoneDetails] = useState(0);

  // Open bottom side of phone details
  function openPhone(ind) {
    if (openedPhoneDetails === itemid + ind) {
      setOpenedPhoneDetails();
    } else {
      setOpenedPhoneDetails(itemid + ind);
    }
  }

  function copyToClipboardPhone(el, index) {
    navigator.clipboard.writeText(el);
    var successSpan = document.getElementById(
      "success-copied-phone-" + itemid + index
    );
    successSpan.classList.add("phone-save-span--blink");
    setTimeout(() => {
      successSpan.classList.remove("phone-save-span--blink");
    }, 2500);
    
  }

  return (
    Array.isArray(phones) &&
    phones
      .filter((phoneDataItem) => Number(phoneDataItem.persons) === 0)
      .map((phoneDataItem, index) => {
        return (
          <div key={index} className="btm-tel__inner btm-tel__inner--data">
            {phoneDataItem.phone !== "unknown" &&
            phoneDataItem.isvalid === "Valid" 
            ? (
              <div>
                <div className="btm-tel__top">
                  <span className="btm-tel__status">
                    <CheckBoxIcon />
                    Verified
                  </span>

                  <span
                    id={"success-copied-phone-" + itemid + index}
                    className="base-email-copy phone-save-span"
                  >
                    Copied to clipboard
                  </span>

                  <span className="btm-tel__phone">
                    <IconButton
                      className="save-phone-button"
                      onClick={() =>
                        copyToClipboardPhone(phoneDataItem.intern_format, index)
                      }
                      color="primary"
                      aria-label="add an alarm"
                    >
                      <ContentCopyIcon />
                    </IconButton>

                    <Divider
                      className="divider-phone"
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />

                    <span className="btm-tel__value">
                      {phoneDataItem.intern_format}
                    </span>
                  </span>

                  <span className="btm-tel__open">
                    <Button
                      className={
                        "btm-tel__button" +
                        (openedPhoneDetails === itemid + index
                          ? " btm-tel__button--opened"
                          : "")
                      }
                      color="primary_grey_light"
                      size="small"
                      onClick={() => openPhone(index)}
                      variant="contained"
                      endIcon={<ArrowForwardIosIcon />}
                      sx={{
                        borderRadius: "2px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        height: "25px",
                      }}
                    >
                      Details
                    </Button>
                  </span>
                </div>

                <Collapse
                  in={openedPhoneDetails === itemid + index}
                  timeout="auto"
                  unmountOnExit
                >
                  <div className="btm-tel__bottom">
                    <div className="btm-tel__bottom-left">
                      <div className="btm-tel__row btm-tel__country">
                        <span>
                          <ReactCountryFlag
                            countryCode={phoneDataItem.country_code}
                            svg
                            style={{
                              width: "1.3em",
                              height: "1.3em",
                            }}
                          />
                        </span>

                        <span>{phoneDataItem.country_name}</span>

                        <span>UTC: {phoneDataItem.UTC}</span>
                      </div>

                      <div className="btm-tel__row">
                        Type:
                        {phoneDataItem.line === "fixed_line"
                          ? " Fixed-line"
                          : phoneDataItem.line === "toll_free"
                          ? " Toll Free"
                          : phoneDataItem.line === "voip"
                          ? " VoIP"
                          : " Mobile"}
                      </div>
                      <div className="btm-tel__row btm-tel__row--intern-call">
                        International calls:{" "}
                        {phoneDataItem.isintern === "yes" ? (
                          <span className="btm-tel__intern-true">
                            <CheckCircleIcon /> Yes
                          </span>
                        ) : (
                          <span className="btm-tel__intern-false">
                            <CancelIcon /> No
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="btm-tel__bottom-right">
                      <div className="btm-tel__row">
                        Local format: {phoneDataItem.local_format}
                      </div>
                      <div className="btm-tel__row">
                        Operator:{" "}
                        {phoneDataItem.carrier !== ""
                          ? phoneDataItem.carrier
                          : "Unknown"}
                      </div>
                      <div className="btm-tel__row btm-tel__row--date-validate">
                        <span>Validated: </span>
                        <span className="btm-tel__date">
                          {convertDate(phoneDataItem.validation_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            ) 
            : 
            <div className="btm-tel__top">
              <span
                className={
                  "btm-tel__status" +
                  (phoneDataItem.isvalid === "unknown"
                    ? " btm-tel__status--unknown"
                    : phoneDataItem.isvalid === "Invalid"
                    ? " btm-tel__status--invalid"
                    : ""
                  )
                }
              >
                {phoneDataItem.isvalid === "Invalid" ? (
                  <CancelIcon />
                ) : <HelpIcon />}

                {phoneDataItem.isvalid === "Invalid"
                  ? "Invalid"
                  : phoneDataItem.isvalid === "unknown"
                  ? "Unknown"
                  : "Invalid"}
              </span>

              <span
                id={"success-copied-phone-" + itemid + index}
                className="base-email-copy phone-save-span"
              >
                Copied to clipboard
              </span>

              <span className="btm-tel__phone">
                <IconButton
                  className="save-phone-button"
                  onClick={() =>
                    copyToClipboardPhone(phoneDataItem.phone, index)
                  }
                  color="primary"
                  aria-label="add an alarm"
                >
                  <ContentCopyIcon />
                </IconButton>

                <Divider
                  className="divider-phone"
                  sx={{ height: 28, m: 0.5 }}
                  orientation="vertical"
                />

                <span className="btm-tel__value">
                  {phoneDataItem.phone}
                </span>
              </span>

            </div>
            
            }
          </div>
        );
      })
  );
}
