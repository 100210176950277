import React, { createContext, useState, useContext, useEffect, useRef } from "react";
import { fetchContent } from "../actions/content";

// Utils
import { downloadCSV, downloadEmailsCSV, downloadExcel } from "../utils"

// Actions
import { prepareData } from "../actions/downloads";

import { useUser } from "../hooks/UserContext";

const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const {
    updateUserDownloads 
  } = useUser();

  const [textSearch, setTextSearch] = useState({
    query: "",
    timestamp: "",
  });
  const [textSearchParams, setTextSearchParams] = useState({
    c_name: true,
    address: true,
    website: true,
  });
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(25);

  const [result, setResult] = useState();
  const [fetching, setFetching] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [chosenItems, setChosenItems] = useState([]);

  // Switch Dashboard user
  const [userDashboard,setUserDashboard] = useState(false);

  const previousPageNumber = useRef(pageNumber);

  const [filters, setFilters] = useState({
    region: [],
    country: [],
    province: [],
    size: [],
    products: [],
    type: [],
    // 'only_importers': false,
    years: [],
    employees: [],
    prodq: [],
    tier: [],
    only_emails: false,
    prod_country: [],
    prod_strict: false,
    time_added: "0",
    time_updated: "0",
    folder: "",
  });

  function clearFilters() {
    // Filters object
    setFilters({
      region: [],
      country: [],
      province: [],
      size: [],
      products: [],
      type: [],
      years: [],
      employees: [],
      prodq: [],
      tier: [],
      // 'only_importers': false,
      only_emails: false,
      prod_country: [],
      prod_strict: false,
      time_added: "0",
      time_updated: "0",
      folder: "",
    });

    // Folder and filters
    setTextSearch({
      query: "", // string
      timestamp: "", // string
    });
    setTextSearchParams({
      c_name: true,
      address: true,
      website: true,
    });
    setSelectedCountries([]);
    setPageNumber(1);
  }

  const [requestParams, setRequestParams] = useState({
    filters: filters,
    time_filter: "0",
    text_search: textSearch,
    text_search_params: textSearchParams,
    per_page: perPage,
    page_number: pageNumber,
    user_expired: false,
  });


  useEffect(() => {

    if (pageNumber === previousPageNumber.current) {
      setPageNumber(1);
    } 

    setRequestParams({
      ...requestParams,
      filters,
      text_search: textSearch,
      text_search_params: textSearchParams,
      page_number: pageNumber,
      per_page: perPage,
    });


    previousPageNumber.current = pageNumber;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, textSearch, pageNumber, perPage]);

  function requestItems() {
    fetchContent(requestParams).then((data) => {
      setResult(data);
      setFetching(false);
    });
  }

  useEffect(() => {
    requestItems();
    setFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  useEffect(() => {
    setChosenItems(selectedIds);
  }, [selectedIds]);

  function selectOneItem(mode,id) {
    if (mode === 'single_download') {
      setSelectedIds([id]);
    } else if (mode === 'checkbox') {
      if (selectedIds?.includes(id)) {
        const newChosenItems = selectedIds.filter((item) => item !== id);
        setSelectedIds(newChosenItems);
      } else {
        setSelectedIds((prevState) => [...prevState, id]);
      }
    }
  }

  function selectAllItems(type) {
    // console.log(type);
  }

  function clearChosenItems() {
    setChosenItems([]);
    setSelectedIds([]);
  }
  // Switch Dashboard of the user
  function SwitchDashboard() {
    setUserDashboard(prev => !prev);
  }

  // 1) singleFile True/False. If False will be take state chosenItems
  // 2) Id if single File download
  // 3) text var of downloaded file format
  // 4) fileNameText = will be written to the database and displayed to user
  // 5) addToDb True/False = Do we need to create new record to dashboard download list of this user

  async function downloadServerFile(singleFile,singleId,fileformats,fileNameText,addToDb) {
    
    var idsToDownload = [];
    if (singleFile) {
      idsToDownload = singleId;
      if (typeof fileformats === "string") {
        fileformats = {
          fullcsv: fileformats === "csv-profile",
          emailcsv: fileformats === "csv-email",
          fullxls: fileformats === "excel-profile",
          emailxls: fileformats === "excel-email",
        };
      }
    } else {
      idsToDownload = chosenItems;
    }

    const data = await prepareData(idsToDownload,requestParams,addToDb,fileNameText);

    const formattedDate = new Date().toISOString().split("T")[0]; 

    var editedName = fileNameText.toLowerCase().replace(/\s+/g, "_");

    if (data["items"].length > 0) {
      if (fileformats.fullcsv) {
        await downloadCSV(data["items"], (editedName?.trim() ? (editedName + "_profiles") : (formattedDate + "_profiles" + "_Vinaty")));
      }
      if (fileformats.emailcsv) {
        await downloadEmailsCSV(data["emails"], (editedName?.trim() ? (editedName + "_emails") : (formattedDate + "_emails" + "_Vinaty")));
      }
      if (fileformats.fullxls) {
        await downloadExcel(data["items"], (editedName?.trim() ? (editedName + "_profiles") : (formattedDate + "_profiles" + "_Vinaty")));
      }
      if (fileformats.emailxls) {
        await downloadExcel(data["emails"], (editedName?.trim() ? (editedName + "_emails") : (formattedDate + "_emails" + "_Vinaty")));
      }
      updateUserDownloads(data["user_downloads"], data["user_counter"]);
    }
  }

  return (
    <ContentContext.Provider
      value={{
        filters,
        setFilters,
        clearFilters,
        pageNumber,
        selectedCountries,
        textSearch,
        textSearchParams,
        setSelectedCountries,
        setPageNumber,
        setTextSearch,
        setTextSearchParams,
        perPage,
        setPerPage,
        result,
        fetching,
        selectOneItem,
        selectAllItems,
        selectedIds,
        setSelectedIds,
        chosenItems,
        setChosenItems,
        clearChosenItems,
        requestParams,
        userDashboard,
        SwitchDashboard,
        downloadServerFile
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContent = () => {
  return useContext(ContentContext);
};
